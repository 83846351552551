import * as React from 'react';
import clsx from 'clsx';
import styles from './dt.module.css';
import Bullets from 'components/bullets';
import Booking from 'components/feedback';
import Tiles from 'components/tile';
import { Skilent, BeyondGreen } from 'components/carousel';
import Layout from '../components/layout';
import GetConsultation from 'components/free-consultation';

import list from './why-stick-to-digital-informatin.json';
import Technology, { TechnologyGroup } from '../../technology';
import Tabs from '../../tabs';
import identificators from 'components/googleAnalyticsIdentificators';
import loadable from '@loadable/component';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const Routes = require('../../routes').Routes;

const tfy = [
  {
    name: 'digital-transformation-agritech',
    title: 'Agritech',
    text: 'We help agritech-related companies and startups deliver fascinating experience for their clients.',
    to: Routes.INDUSTRIES_AGRITECH,
  },
  {
    name: 'digital-transformation-events-managment',
    title: 'Events production',
    text: 'We build smart cloud-based solutions for event providers.',
    to: Routes.INDUSTRIES_EVENTS_MANAGMENT,
  },
  {
    name: 'digital-transformation-foodtech',
    title: 'Foodtech',
    text: 'We help delivery services and Foodtech companies reach new customers.',
    to: Routes.INDUSTRIES_FOODTECH,
  },
];

const LIST = [
  {
    title: 'INTRO call',
  },
  {
    title: 'Discovery phase',
  },
  {
    title: 'Design phase',
  },
  {
    title: 'Development phase',
  },
  {
    title: 'Product launch',
  },
  {
    title: 'support',
  },
];

interface Props {
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));

function Main({ location }: Props) {
  const faqData = useFaqData('allStrapiService', 'Digital Transformation');

  return (
    <Layout
      title="Digital Transformation"
      subtitle="Leverage the full power of top-notch digital technologies to boost your efficiency and workflow, reduce unnecessary costs and acquire robust security."
      metaData={{ main: meta.digitalTransformation, faq: faqData?.list }}
      pathname={location.pathname}
    >
      <section className={clsx('section', styles.center)}>
        <div className="inner">
          <h2 className="title">Why stick to digital transformation?</h2>
          <p className="subtitle">Keep up with the times to streamline your business.</p>
          <Bullets list={list} className={styles.bullets} />
        </div>
      </section>
      <SwiperSlider>
        <BeyondGreen />
        <Skilent />
      </SwiperSlider>
      <section className={clsx('section', styles.transform)}>
        <div className="inner">
          <h2 className="title">Domains we empower</h2>
          <p className="subtitle">Evolving new concepts for businesses</p>
          <Tiles list={tfy} className={styles.tfy} />
        </div>
      </section>
      <GetConsultation
        title="Start your digital transformation journey with us"
        text="Unlocking your business potential"
        linkText="Get a consultation"
        linkId={identificators.GET_A_CONSULTATION_BANNER}
      />
      <section className="section">
        <div className="inner">
          <h2 className="title">Work process</h2>
          <Tabs list={LIST} btnClassName="tabs__btn_30" isArrow>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Here at Brocoders, we work shoulder-to-shoulder with you in an open manner
                  throughout the entire product life-cycle.
                </p>
                <p>
                  To cover all details and ensure the completeness of the requirements elicitation,
                  we start from a first call and meetings with a customer.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 hour</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      NDA <br />
                      Consultation
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account Manager <br />
                      Stakeholder <br />
                      Technical consultant
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <TechnologyGroup technology={['skype', 'zoom', 'googleMeet']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  We apply the best relevant market research methodologies, study the business
                  processes and analyze competitors along with potential risks to end up with a
                  robust business strategy.
                </p>
                <p>
                  Either you are a startup that requires a new product, or a business that needs a
                  new feature to be integrated into an existing solution, we are ready to evaluate
                  your idea and provide a piece of expert advice.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Up to 3 days</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      {' '}
                      Project Proposal <br />
                      Contract for Prototype service
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      {' '}
                      Account manager <br />
                      Business analyst <br />
                      Stakeholders
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <h3>Low fidelity prototype</h3>
                <p>
                  Based on gathered data and driven by the iterative development model, we identify
                  the feature flow and prepare the wireframes along with a low-fidelity MVP
                  prototype.
                </p>
                <h3>Feature-based estimation</h3>
                <p>
                  It’s crucial to evaluate the entire project and provide a customer with a clear
                  vision of how much time and money the development process might take. That said,
                  we use a feature decomposition to split the functionality into small parts, which
                  can then be analyzed by our technical experts.
                </p>
                <p>
                  Our customers have full access to all relevant data, so they can assess further
                  steps, as well as protect the budget to investors. Once it’s done, we adjust a
                  toolset and a tech stack for the development stage.
                </p>
                <h3>Design</h3>
                <p>
                  Our UI/UX designers have extensive experience in creating eye-catching and
                  user-friendly apps that proved to be remarkable solutions. We stick to Figma to
                  design the UI/UX elements for our MVP projects. If requested, we provide a
                  customer with an interactive prototype, so he can test the interface usability and
                  leave his feedback.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 month</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Low-fidelity prototype in Figma <br />
                      Feature-based estimation <br />
                      Feature-decomposition <br />
                      Graphic Project in Figma <br />
                      Contract for development
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Designer <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Technical consultant
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <Technology list={['figma', 'notion']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  It’s time to establish a development environment, define the project’s
                  architecture, make a roadmap and choose the right candidates to set up a team that
                  fits your company’s requirements and vision. To strengthen the overall performance
                  and ensure everything is working properly, we carry out functional, integration
                  and usability tests. We also integrate manual and auto QA testing at this stage.
                </p>
                <p>
                  At the end of each sprint, we provide the stakeholders with a demo to showcase the
                  workflow and the functionality we’ve already made. Hence, all the stakeholders
                  have a clear vision of the working process, so they can leave feedback and make
                  sure we are on the right path.
                </p>
                <h3>Project management</h3>
                <p>
                  We involve experienced project managers to take care of every step and the entire
                  technological process, so you can pay maximum attention to your strategic and
                  business goals. Based on years of practice, we’ve come up with a robust and
                  well-established workflow, which proved to be highly effective and
                  client-oriented.
                </p>
                <h3>Transparent work process</h3>
                <p>
                  To ensure a project’s safety, we apply the latest security and agile-driven
                  practices, as well as provide transparent reports, according to a customer’s
                  request. Besides, you can come to our office any time to work with our team and
                  look at the whole working process yourself. Moreover, we can strengthen a project
                  by applying extra resources once you request to do so.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Depends on project</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Environment setup <br />
                      Staging and production servers <br />
                      Intermediate releases <br />
                      Weekly Reports
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Development team <br />
                      QA team <br />
                      Devops
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'gatsby']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  We believe it’s better to double-check everything from scratch, so we go through
                  the pre-launch checklist to ensure the project is fully-functioning and ready for
                  deployment.
                </p>
                <p>
                  Then we can either upload your solution to the production server in case it’s a
                  web-driven app or submit it to online stores such as the AppStore or Google Play
                  in case it’s a mobile-based app.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Final release</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Designer <br />
                      Project Manager <br />
                      Devops
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <Technology list={['amazon']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Due to the client-oriented approach, our company keeps an eye on your product
                  after the final release and helps you scale it once the total number of end-users
                  starts growing.
                </p>
                <p>
                  At Brocoders, we care deeply about our customers and the solutions we develop, so
                  we provide continuous maintenance and support to our clients, as well as assign a
                  dedicated developer to a project to fix any possible bugs and respond to
                  inquiries.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Fixes and improvements</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account manager <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Development team
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </Tabs>
        </div>
      </section>
      <section className="inner">
        <FAQ data={faqData} />
      </section>
      <Booking />
    </Layout>
  );
}

export default Main;
